@mixin routes-theme($theme) {
  #pending-routes-count {
    color: #004a84;
  }
  #pending-routes-awaiting-approval {
    color: #293239;
  }
  #help-icon {
    color: rgba(41, 41, 41, 0.87);
  }
}
