@use 'sass:map';
@use '@angular/material' as mat;
@import '@angular/material/theming';

:host {
  background-color: #f3f4f5;
}

.split-route-sources-filters,
.split-route-sources-submission-button {
  padding: 0% 2%;
}

.split-route-sources-filters {
  grid-area: filter;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  font-size: 0.75rem;
  margin: 5% 2% 0% 2%;
  .search-filter {
    width: 60%;
  }
  .status-filter {
    width: 35%;
  }
}
.split-route-sources-table {
  grid-area: sources;
  overflow-y: scroll;
  height: 100%;

  .mat-row,
  .mat-header-row {
    display: flex;
    border-bottom: 1px solid #e2e4e6;
    align-items: center;
    padding-left: 0.5rem;
  }
  .mat-header-row {
    height: 1.5rem;
  }
  .mat-cell,
  .mat-header-cell {
    border: none;
    padding-left: 1.5rem;
    word-wrap: initial;
    display: table-cell;
    padding: 0px 10px;
    line-break: unset;
    width: 100%;
    white-space: nowrap;
    vertical-align: middle;
  }
  .mat-row.selected {
    background-color: #e5f1f7;
  }
  .mat-row.selected p {
    color: #004a84;
    font-weight: 600;
  }
  ::ng-deep .mat-checkbox-label {
    padding-left: 1rem;
  }
}

.split-route-sources-paginator {
  grid-area: paginator;
  @include mat.elevation(3);
  padding: 0px 1rem 0px 0px;
}

.split-route-sources-submission-button {
  grid-area: button;
  display: flex;
  align-items: center;
  justify-content: space-around;
  button {
    height: 40%;
    width: 90%;
    background: #2d8ed9;
  }
}

.split-route-show-routes-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  button {
    height: 55%;
    width: 90%;
    background: #2d8ed9;
  }
}
