@use 'sass:map';
@use '@angular/material' as mat;
@use '../route-splitting-source-list/route-splitting-source-list.component.scss';
@import '@angular/material/theming';

@mixin route-splitting-source-detail($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  #source-detail-additional-source-routes {
    @extend .split-route-sources-submission-button;
    flex-direction: column;
    justify-content: space-evenly;
    // padding: 0.5rem 2rem 1rem 2rem;

    border-bottom: 1px solid #d0d3d5;
    border-right: 1px solid #d0d3d5;
    border-radius: 0.25rem;
    background-color: white;
    p {
      align-self: flex-start;
      padding-left: 0.5rem;
    }
    button {
      height: 55%;
      width: 90%;
      background: #2d8ed9;
    }
  }

  .source-route-item {
    .mat-expansion-panel-body {
      padding: 0px;
    }

    .source-route-item-header {
      display: flex;
      flex-direction: column;
      padding: 1rem 1.5rem 1rem 2rem;
      border-bottom: 1px solid #d0d3d5;
      border-right: 1px solid #d0d3d5;
      background-color: white;
      border-radius: 0.25rem;

      .mat-icon-button {
        align-self: flex-end;
      }
      p {
        margin-bottom: 0px;
        align-self: flex-start;
      }
      p:first-of-type {
        font-size: 0.8rem;
      }
    }

    .route-accordion-panel {
      > .mat-expansion-panel-header {
        border-right: 1px solid #d0d3d5;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
      }
    }

    .accordion-container {
      background-color: #f3f4f5;
      padding: 1rem 0rem;
      min-height: 5vh;
    }

    .select-all-route-stops {
      font-size: 0.8rem;
      color: #49555e;
      border-bottom: 1px solid #d0d3d5;
      border-right: 1px solid #d0d3d5;

      background-color: mat-color($background, card);
      border-radius: 0.25rem;
      height: 4rem;
      display: flex;
      align-items: center;
      > .mat-checkbox {
        padding-left: 2rem;
      }
      &.blue-bg {
        background-color: #e5f1f7;
      }
    }

    .smaller-font {
      padding-left: 1rem;
    }

    .source-route-stops {
      min-height: 5vh;
      .mat-expansion-panel {
        margin: 1rem 1.5rem 0rem 1.5rem;
        border-radius: 0.25rem;
      }
      .mat-expansion-panel-header {
        font-size: 1rem;
        padding-left: 0rem;
        .mat-content {
          align-items: center;
          .mat-checkbox {
            margin-left: 2rem;
          }
        }
      }
      .mat-expansion-panel-body {
        padding-left: 2rem;
        overflow-y: scroll;
      }
      .source-stop-nav-icon {
        > svg {
          height: 65%;
          width: 65%;
        }
      }
      p {
        display: flex;
        align-items: center;
        > mat-icon {
          padding-left: 0.5rem;
        }
      }
    }
  }
}
