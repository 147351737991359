@use '@angular/material' as mat;

@include mat.core();

// pattern library
@import '@zonar-ui/material/zonar-ui-material.theme';
@import '@zonar-ui/core/zonar-ui-core.theme';

// application themes
@import 'app.breakpoints.scss';
@import 'route-display/components/route-details/route-details.theme.scss';
@import 'route-display/components/routes/routes.theme.scss';
@import '../app/features/route-splitting/route-splitting.component.scss';

@mixin apply-zonar-theme($theme) {
  // @include angular-material-theme($theme);
  @include mat.all-component-themes($theme);
  // Custom component themes added here...
  @include zonar-ui-core-theme($theme);
  @include routes-theme($theme);
  @include route-details-theme($theme);

  // route splitting
  @include route-splitting($theme);
}

.zonar-default-theme {
  @include apply-zonar-theme($zonar-default-theme);
  background-color: #e1e4e6;
  color: #292929;
}

.zonar-dark-theme {
  @include apply-zonar-theme($zonar-dark-theme);
  background-color: #292929;
}

.zonar-default-theme .mat-toolbar {
  background-color: #e1e4e6;
}

.zonar-dark-theme .routes-overview-container,
.zonar-dark-theme .routes-container,
.zonar-dark-theme #route-details-container,
.zonar-dark-theme .mat-select-panel,
.zonar-dark-theme #route-preview-container,
.zonar-dark-theme #edit-route-content {
  background-color: rgb(73, 85, 94);
}

.zonar-dark-theme h2,
.zonar-dark-theme #route-details-preview-container,
.zonar-dark-theme #route-details-content h4,
.zonar-dark-theme #route-details-content p,
.zonar-dark-theme .stop-name-container,
.zonar-dark-theme .user-details .user-email,
.zonar-dark-theme .user-details .user-name,
.zonar-dark-theme #help-icon {
  color: white;
}

.zonar-dark-theme #pending-routes-count,
.zonar-dark-theme #dark-theme-icon {
  color: #87bcf5;
}
.zonar-dark-theme #pending-routes-awaiting-approval,
.zonar-dark-theme .nav-stop-name {
  color: #a2aaaf;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.basic-content .quad-layout .quad-content .route-edit-toolbar {
  background: white;
}

.zonar-dark-theme .basic-content .quad-layout .quad-content .routes-overview-container,
.zonar-dark-theme .basic-content .quad-layout .quad-content #route-details-container,
.zonar-dark-theme .basic-content .quad-layout .quad-content #route-preview-container #edit-route-content,
.zonar-dark-theme .basic-content .quad-layout .quad-content .route-edit-toolbar,
.zonar-dark-theme .basic-content .quad-layout .quad-content .routes-overview-container,
.zonar-dark-theme .basic-content .quad-layout .quad-drawer-content app-routes,
.zonar-dark-theme .quad-content {
  background-color: rgb(73, 85, 94);
}

.zonar-dark-theme .basic-content {
  background-color: #292929;
}
