.backdrop-blur {
  backdrop-filter: blur(0.25rem);
}

svg.header-zonar-logo {
  .header-logo-container {
    padding: 0.75rem 0.75rem 0.75rem 0;
  }

  g text {
    transform: translateX(-50px);
  }

  .header-logo-copyright,
  g path {
    display: none;
  }
}

.hide-scrollbar {
  overflow-y: scroll; /* Enable scrolling */
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0; /* Hide scrollbar for WebKit browsers */
}

div.mat-drawer-inner-container {
  overflow-y: scroll; /* Enable scrolling */
}

div.mat-drawer-inner-container::-webkit-scrollbar,
mat-table::-webkit-scrollbar,
mat-accordion::-webkit-scrollbar,
app-route-splitting-new-route::-webkit-scrollbar,
app-route-splitting-source-detail::-webkit-scrollbar {
  width: 0; /* Hide scrollbar for WebKit browsers */
}

mat-table {
  overflow-y: scroll; /* Enable scrolling */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

mat-table::-webkit-scrollbar {
  width: 0; /* Hide scrollbar for WebKit browsers */
}

mat-accordion {
  overflow-y: scroll; /* Enable scrolling */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

mat-accordion::-webkit-scrollbar {
  width: 0; /* Hide scrollbar for WebKit browsers */
}

div.mat-drawer-inner-container,
mat-table,
mat-accordion,
app-route-splitting-new-route,
app-route-splitting-source-detail {
  overflow-y: scroll; /* Enable scrolling */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}

// this prevents opening a matdialog from scrolled position from negatively shifting the html body
// https://github.com/angular/components/issues/7390
.cdk-global-scrollblock {
  position: static !important;
  width: initial !important;
  overflow-y: inherit !important;
}

.spacer {
  flex: 1 1 auto;
}

.full-width {
  width: 100%;
}
