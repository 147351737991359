@mixin route-details-theme($theme) {
  $primary: map-get($theme, primary);

  #route-details-body,
  .route-details-status-icon-container mat-icon {
    background-color: white;
  }

  #route-details-content h4,
  .route-details-status-text-container p {
    color: #292929;
  }
  #route-details-container input {
    color: #2d8ed9;
  }

  .nav-stop-name {
    color: #292929;
  }
}
