@use 'sass:map';
@use '@angular/material' as mat;
@import './components/route-splitting-source-detail/route-splitting-source-detail.component.scss';
@import './components/route-splitting-new-route/route-splitting-new-route.component.scss';
@import '@angular/material/theming';

@mixin route-splitting($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .route-splitting {
    background: mat-color($background, card);
    color: mat-color($foreground, base);

    // @include mat.elevation(6);

    height: 88vh;

    margin: 0px 1rem;

    display: grid;
    grid-template-columns: 1fr 1fr 3fr;

    grid-template-rows: 1fr 0.4fr 5fr;
    grid-template-areas:
      'sources dest details'
      'sources dest menu'
      'sources dest map';

    p {
      color: #939393;
      font-family: Open Sans;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .smaller-font {
      font-size: 0.75rem;
    }

    .medium-font {
      font-size: 1rem;
    }

    .blue {
      color: #4a8dd3;
    }
    .dark-grey {
      color: mat-color($primary);
    }

    app-route-splitting-source-list {
      grid-area: sources;
      display: grid;
      grid-template-areas:
        'filter'
        'sources'
        'paginator'
        'button';
      grid-template-rows: 8vh 5fr 1fr 1fr;
    }
    app-route-splitting-source-detail {
      grid-area: sources;
      display: grid;
      grid-template-rows: 8vh 1fr;
      grid-template-areas:
        'button'
        'routes';
      overflow-y: scroll;
    }
    @include route-splitting-source-detail($theme);

    app-route-splitting-new-route {
      grid-area: dest;
      background-color: #f3f4f5;
      display: grid;
      grid-template-rows: 8vh 1fr;
      overflow-y: scroll;
    }
    @include route-splitting-new-route($theme);
    // .route-splitting-dest {
    //   grid-area: dest;
    //   border: 1px red solid; // temp for visualization
    //   // background-color: #f3f4f5;
    // }
    app-route-splitting-new-route-detail {
      grid-area: details;
      display: grid;
      grid-template-rows: 8vh 1fr;
    }
    // .route-splitting-details {
    //   grid-area: details;
    //   border: 1px red solid; // temp for visualization
    // }
    .route-splitting-edit-menu {
      background-color: #f3f4f5;
      grid-area: menu;
    }
    .route-splitting-map {
      grid-area: map;
    }
  }
}
