@mixin route-splitting-new-route($theme) {
  .new-route-name-container {
    height: 8vh;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    .mat-form-field {
      width: 85%;
    }
  }

  .smaller-font {
    padding-left: 1rem;
  }

  .new-route-stops {
    app-multi-drag-drop {
      .mat-expansion-panel-header {
        font-size: 1rem;
        padding-left: 0rem;
        .mat-content {
          align-items: center;
          .mat-checkbox {
            margin-left: 2rem;
          }
        }
      }
      .mat-expansion-panel {
        margin: 1rem 1.5rem 0rem 1.5rem;
        border-radius: 0.25rem;
      }
      .mat-content {
        align-items: center;
      }
    }
    overflow-y: scroll;
  }
}
